import {Button, Col, Form, Input, message, Popconfirm, Row, Select, Space, Table} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import {deviceService} from "../../service/device";
import dayjs from 'dayjs';
import {DeviceOperation} from "./DeviceOperation";
import {ExportOutlined} from "@ant-design/icons";

export const DeviceListPage = () => {

    const [msgApi, messageCtxHolder] = message.useMessage()

    const [form] = Form.useForm();

    // 抽屉详情打开
    const [drawerOpen, setDrawerOpen] = useState(false);
    // 批次列表
    const [batchList, setBatchList] = useState<any[]>([]);
    // 选中的批次
    const [selectedBatchId, setSelectedBatchId] = useState<number>();
    // 分页参数
    const [pageNum, setPageNum] = useState(1);
    // const pageParam = useRef({pageSize: 10, pageNum: 1});
    // 分页参数
    const [pageSize, setPageSize] = useState(10);
    // 总数
    const [total, setTotal] = useState(0);
    // 设备列表
    const [deviceList, setDeviceList] = useState<any[]>([]);
    // 选中的设备
    const [selectedDevice, setSelectedDevice] = useState<any>();
    // 设备状态-筛选项
    const [selectedDeviceStatus, setSelectedDeviceStatus] = useState(null)
    // 设备ID-筛选项
    const [selectedDeviceId, setSelectedDeviceId] = useState<string | null>(null)
    // 删除loading
    const [deleteLoading, setDeleteLoading] = useState(false);
    // 导出loading
    const [exportLoading, setExportLoading] = useState(false)


    useEffect(() => {
        init();
    }, [])

    const init = () => {
        deviceService.getEnableDeviceBatchList()
            .then(res => {
                // console.log("res", res);
                const responseMsg = res.data;
                if (responseMsg.status == 0) {
                    setBatchList(responseMsg.data);
                } else {
                    msgApi.error(responseMsg.msg).then();
                }
            });
    }

    /**
     * 搜索设备列表
     */
    const search = useCallback(() => {
        const params: any = {
            deviceStatus: selectedDeviceStatus === "" ? null : selectedDeviceStatus,
            deviceId: (selectedDeviceId && selectedDeviceId.trim() !== "") ? selectedDeviceId.trim() : null
        };
        if (selectedBatchId) {
            params["batchId"] = selectedBatchId;
        }
        // console.log("查询参数", params);
        // console.log("查询页码", pageParam);
        deviceService.getDeviceList({
            pageNum: pageNum,
            pageSize: pageSize,
            ...params
        })
            .then(res => {
                // console.log("res", res);
                const responseMsg = res.data;
                if (responseMsg.status == 0) {
                    // console.log(responseMsg.data);
                    setDeviceList(responseMsg.data.content);
                    setTotal(responseMsg.data.totalElements);
                } else {
                    msgApi.error(responseMsg.msg);
                }
            })
            .catch(err => {
                msgApi.error("发生未知错误").then();
            })
    }, [selectedBatchId, selectedDeviceStatus, selectedDeviceId, pageNum, pageSize])


    useEffect(() => {
        search();
    }, [search]); // 依赖于search函数本身，search函数的依赖项变化会触发它重新创建

    const resetPageNum = () => {
        setPageNum(1);
    }


    /**
     * 批量删除设备
     */
    const deleteList = () => {
        setDeleteLoading(true);
        const params: any = {
            deviceStatus: selectedDeviceStatus === "" ? null : selectedDeviceStatus,
            deviceId: (selectedDeviceId && selectedDeviceId.trim() !== "") ? selectedDeviceId.trim() : null
        };
        if (selectedBatchId) {
            params["batchId"] = selectedBatchId;
        }
        console.log(`删除参数：${JSON.stringify(params)}`);
        // 校验params中必须有一个参数，不能所有的属性都为空
        if (!params.batchId && !params.deviceStatus && !params.deviceId) {
            msgApi.error("请至少选择一个条件进行筛选,否则会全部删除设备！", 5).then();
            setDeleteLoading(false);
            return;
        }
        deviceService.deleteDeviceList(params)
            .then(res => {
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    msgApi.success(`删除成功,共删除:${responseMsg.data}条`).then();
                    search();
                } else {
                    msgApi.error(responseMsg.msg).then();
                }
                setDeleteLoading(false);
            })
            .catch(err => {
                msgApi.error("发生未知错误").then();
                setDeleteLoading(false);
            })
    }

    /**
     * 删除单个设备
     * @param record
     */
    const deleteOne = (record: any) => {
        setDeleteLoading(true);
        deviceService.deleteDevice(record)
            .then(res => {
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    msgApi.success(`删除成功,共删除:${responseMsg.data}条`);
                    search();
                } else {
                    msgApi.error(responseMsg.msg);
                }
                setDeleteLoading(false);
            })
            .catch(err => {
                msgApi.error("发生未知错误").then();
                setDeleteLoading(false);
            })
    }

    /**
     * 对单个设备进行复机
     * @param record
     */
    const activeManual = (record: any) => {
        deviceService.activeManual(record)
            .then(res => {
                const responseMsg = res.data;
                if (responseMsg.status === 0) {
                    msgApi.success(`手动复机成功`).then();
                    search();
                } else {
                    msgApi.error(responseMsg.msg).then();
                }
            })
            .catch(err => {
                msgApi.error("发生未知错误").then();
            })
    }

    const onDeviceClick = (record: any) => {
        setSelectedDevice(record);
        setDrawerOpen(true);
    }

    const downloadFile = useCallback(() => {
        // console.log('onDownLoad')
        setExportLoading(true);
        const params: any = {
            deviceStatus: selectedDeviceStatus === "" ? null : selectedDeviceStatus,
            deviceId: (selectedDeviceId && selectedDeviceId.trim() !== "") ? selectedDeviceId.trim() : null
        };
        if (selectedBatchId) {
            params["batchId"] = selectedBatchId;
        }
        deviceService.exportDeviceList(params)
            .then(res => {
                // console.log("download res",res);
                setExportLoading(false);
                // console.log("download header", res.headers['content-type']);
                if (res.headers['content-type'] === 'application/json') {
                    msgApi.error("没有可以下载的数据").then();
                    return;
                }
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${new Date().getTime()}.xlsx`); // 设置下载文件的文件名
                document.body.appendChild(link);
                link.click();
                // 清理并移除创建的元素和URL对象
                // @ts-ignore
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch(err => {
                console.error("download err", err);
                msgApi.error("没有可以下载的数据").then();
                setExportLoading(false);
            })

    }, [selectedBatchId, selectedDeviceId, selectedDeviceStatus])

    const columns = [
        {
            key: "id",
            dataIndex: "id",
            title: "序号",
        },
        {
            key: "deviceId",
            dataIndex: "deviceId",
            title: "设备号",
            render: (text: any, record: any) => {
                return <a onClick={() => onDeviceClick(record)}>{text}</a>
            }
        },
        {
            key: "batchName",
            dataIndex: "batchName",
            title: "导入批次",
        },
        {
            key: "deviceTypeTitle",
            dataIndex: "deviceTypeTitle",
            title: "设备类型",
        },
        {
            key: "deviceStatus",
            dataIndex: "deviceStatus",
            title: "设备状态",
            render: (text: any, record: any) => {
                switch (record.deviceStatus) {
                    case "ACTIVE":
                        return <span style={{color: "green"}}>已激活（旧）</span>
                    case "ACTIVE_GIFT":
                        return <span style={{color: "green"}}>免费激活</span>
                    case "ACTIVE_PAY":
                        return <span style={{color: "green"}}>付费激活</span>
                    case "ACTIVE_MANUAL":
                        return <span style={{color: "#032c03"}}>手动激活</span>
                    case "INACTIVE":
                        return <span style={{color: "gray"}}>未激活</span>
                    case "DOWN_MANUAL":
                        return <span style={{color: "#CC5233"}}>手动停机</span>
                    case "DOWN_EXPIRE":
                        return <span style={{color: "#7c0303"}}>到期停机</span>
                    default:
                        return <span style={{color: "red"}}>未知</span>
                }
            }
        },
        {
            key: "createTime",
            dataIndex: "createTime",
            title: "创建时间",
            render: (text: any) => {
                if (text) {
                    return dayjs.unix(text).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        },
        {
            key: "activeTime",
            dataIndex: "activeTime",
            title: "上一次激活时间",
            render: (text: any) => {
                if (text) {
                    return dayjs.unix(text).format('YYYY-MM-DD HH:mm:ss')
                }
                return text
            }
        },
        {
            key: "operation",
            title: "操作",
            render: (text: any, record: any) => {
                const manualActiveEnable = record.deviceStatus === "DOWN_MANUAL" || record.deviceStatus === "DOWN_EXPIRE" || record.deviceStatus === "INACTIVE";
                return <Space>

                    <Popconfirm title={"确认复机？"} description={"该操作不可撤销！请谨慎操作"}
                                okText={"确认"}
                                cancelText={"取消"}
                                disabled={!manualActiveEnable}
                                onConfirm={() => activeManual(record)}>
                        <Button type={"link"} disabled={!manualActiveEnable}>手动复机</Button>
                    </Popconfirm>
                    <Popconfirm title={"确认删除？"} description={"该操作不可撤销！请谨慎操作"}
                                okText={"确认"}
                                cancelText={"取消"}
                                onConfirm={() => deleteOne(record)}>
                        <Button type={"link"} danger={true}>删除</Button>
                    </Popconfirm>

                </Space>
            }
        }
    ];

    const onBatchChange = (value: number, option: any) => {
        console.log("onBatchChange", value, option)
        setSelectedBatchId(value)
        resetPageNum();
    }

    const resetValues = () => {
        form.resetFields();
        // console.log("onReset");
        setSelectedBatchId(undefined);
        setPageNum(1);
    }

    return <div>
        {messageCtxHolder}
        <Form key={"search_form"} form={form}>
            <Row>
                {/*<Form.Item*/}
                {/*    name={"statisticalDate"}*/}
                {/*    labelCol={{ span: 6 }}*/}
                {/*    wrapperCol={{ span: 18 }}*/}
                {/*    label={"统计日期"}*/}
                {/*    // initialValue={[dayjs().subtract(7, 'days').startOf('days'), dayjs().endOf('days')]}*/}
                {/*>*/}
                {/*    /!*<RangePicker />*!/*/}
                {/*</Form.Item>*/}

                <Col span={8}>
                    <Form.Item label={"导入批次"} labelCol={{span: 8}} wrapperCol={{span: 16}}
                               name={"batchId"} initialValue={[]}>
                        <Select
                            options={batchList.map(item => {
                                return {label: item.batchName, value: item.id}
                            }).sort((a, b) => a.label.localeCompare(b.label))}
                            onChange={onBatchChange}
                            showSearch={true}
                            optionFilterProp="label"
                            filterOption={(input, option) => {
                                return option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }}
                            placeholder='请选择导入批次'
                            maxTagCount={1}
                            allowClear={true}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={"设备状态"} labelCol={{span: 8}} wrapperCol={{span: 16}}
                               name={"deviceStatus"}
                    >
                        <Select placeholder={"请选择设备状态"}
                                onChange={(value) => {
                                    setSelectedDeviceStatus(value)
                                    resetPageNum();
                                }}
                                allowClear={true}
                        >
                            <Select.Option value={""}>全部</Select.Option>
                            <Select.Option value={'ACTIVE_GIFT'}>免费激活</Select.Option>
                            <Select.Option value={'ACTIVE_PAY'}>付费激活</Select.Option>
                            <Select.Option value={'ACTIVE_MANUAL'}>手动激活</Select.Option>
                            <Select.Option value={'INACTIVE'}>未激活</Select.Option>
                            <Select.Option value={'DOWN_MANUAL'}>手动停机</Select.Option>
                            <Select.Option value={'DOWN_EXPIRE'}>到期停机</Select.Option>
                            <Select.Option value={'ACTIVE'}>已激活（旧状态）</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={"设备ID"} labelCol={{span: 8}} wrapperCol={{span: 16}}
                               name={"deviceId"}
                    >
                        <Input placeholder={"设备ID精确匹配"}
                               onChange={(e) => {
                                   // console.log(e.target.value);
                                   setSelectedDeviceId(e.target.value)
                                   resetPageNum();
                               }}
                               allowClear={true}
                        />
                    </Form.Item>
                </Col>
                {/*<Col span={2} style={{ textAlign: 'right' }}>*/}
                {/*    <Button onClick={onDownLoad} type='primary'>下载表格数据</Button>*/}
                {/*</Col>*/}
            </Row>
            <Row>
                <Col span={15}></Col>
                <Col span={9}>
                    <Form.Item>
                        <Space size={'large'}>
                            <Button type={'primary'} onClick={() => {
                                search();
                            }} loading={false}>查询</Button>
                            <Button type={'default'} onClick={() => {
                                resetValues();
                            }}>重置</Button>
                            <Popconfirm title={"确认导出表格数据?"}
                                        description={'可能会耗费比较长的时间'}
                                        okText={'确认'}
                                        cancelText={'取消'}
                                        onConfirm={() => downloadFile()}
                            >
                                <Button icon={<ExportOutlined/>} loading={exportLoading}
                                        type={'default'}>下载表格数据</Button>
                            </Popconfirm>
                            <Popconfirm title={'确认删除？'}
                                        description={`即将删除查询到的 ${total} 条数据。该操作不可撤销！请谨慎操作`}
                                        onConfirm={deleteList}
                                        okText={'确认'}
                                        cancelText={'取消'}
                            >
                                <Button type={'primary'} loading={deleteLoading} danger>批量删除表格数据</Button>
                            </Popconfirm>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>

        <Table rowKey={"id"}
               columns={columns}
               pagination={{
                   position: ["bottomRight"],
                   pageSize: pageSize,
                   current: pageNum,
                   total: total,
                   onChange: (page, pageSize) => {
                       // console.log("page", page);
                       setPageNum(page);
                       setPageSize(pageSize);
                   }
               }}
               dataSource={deviceList}
        />

        <DeviceOperation visible={drawerOpen}
                         device={selectedDevice}
                         deviceBatchList={batchList}
                         onClose={() => {
                             setDrawerOpen(false)
                             search();
                         }}/>


    </div>
}
