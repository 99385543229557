import {ConfigProvider} from 'antd';
import React from 'react';
import {Home} from "./pages/home";
import {Route, Routes} from "react-router-dom";
import {RequireAuth} from "./route";
import {DeviceListPage} from "./pages/device";
import {AuthProvider} from "./context";
import {SignIn} from "./pages/auth";
import {PackageGroupList, PackageItemList} from "./pages/package";
import {OrderList} from "./pages/order";
import {DeviceBatchList} from "./pages/device-batch";
import {WxMchList} from "./pages/config/WxMchList";
import {CMWxMchList} from "./pages/config-monitor/CMWxMchList";
import {CMWxMpMchRel} from "./pages/config-monitor/CMWxMpMchRel";
import {CMCardConfig} from "./pages/config-monitor/CMCardConfig";
import {CMCardOrderLog} from "./pages/config-monitor/CMCardOrderLog";
import {CMPackageConfig} from "./pages/config-monitor/CMPackageConfig";
import {CMCommonConfig} from "./pages/config-monitor/CMCommonConfig";
import {DataView} from "./pages/data/DataView";

import zhCN from 'antd/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';


const App: React.FC = () => {
    dayjs.locale('en');
    return (<ConfigProvider theme={{token: {colorPrimary: '#00b96b'}}} locale={zhCN}>
            <AuthProvider>
                <Routes>
                    <Route path={"/"} element={<RequireAuth><Home/></RequireAuth>}>
                        <Route path={"/data"} element={<DataView/>}></Route>
                        <Route path={"/device/list"} element={<DeviceListPage/>}></Route>
                        <Route path={"/device/batch/list"} element={<DeviceBatchList/>}></Route>
                        <Route path={"/package/group/list"} element={<PackageGroupList/>}></Route>
                        <Route path={"/package/list"} element={<PackageItemList/>}></Route>
                        <Route path={"/order/list"} element={<OrderList/>}></Route>
                        <Route path={"/config/wx/list"} element={<WxMchList/>}></Route>
                        <Route path={"/config-monitor/mch/list"} element={<CMWxMchList/>}></Route>
                        <Route path={"/config-monitor/mp/mch/rel"} element={<CMWxMpMchRel/>}></Route>
                        <Route path={"/config-monitor/card/list"} element={<CMCardConfig/>}></Route>
                        <Route path={"/config-monitor/card/order/log"} element={<CMCardOrderLog/>}></Route>
                        <Route path={"/config-monitor/package/config"} element={<CMPackageConfig/>}></Route>
                        <Route path={"/config-monitor/package/common/config"} element={<CMCommonConfig/>}></Route>
                    </Route>
                    <Route path={"auth/login"} element={<SignIn/>}/>
                </Routes>
            </AuthProvider>
        </ConfigProvider>
    )
};

export default App;
