import {Table} from "antd";
import './$7DaysProductTable.css';
import {useEffect, useState} from "react";
import {orderService} from "../../service";
import {MD5} from "crypto-js";

export const $7DaysProductTable = () => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    // const [data, setData] = useState(_tempData.data)
    // 分页参数
    const [pageNum, setPageNum] = useState(1);
    // 分页参数
    const [pageSize, setPageSize] = useState(10);

    const columns = [
        {
            key: "product_id",
            dataIndex: "product_id",
            title: "套餐ID",
        },
        {
            key: "product_name",
            dataIndex: "product_name",
            title: "套餐名称",
        },
        {
            key: "user_count",
            dataIndex: "user_count",
            title: "付费人数",
        },
        {
            key: "order_count",
            dataIndex: "order_count",
            title: "订单总数",
        },
        {
            key: "total_pay_price",
            dataIndex: "total_pay_price",
            title: "总金额",
        },
        {
            key: "order_date",
            dataIndex: "order_date",
            title: "日期",
        }
    ]
    useEffect(() => {
        orderService.$7DaysProductData().then((res) => {
            // console.log("$7DaysProductData", res)
            setData(res.data.data)
            setLoading(false)
        })
    }, []);

    return <div>
        <div className={'title'}>
            <span>7日套餐购买表格</span>
        </div>
        <Table columns={columns}
               rowKey={(record: any) => `${MD5(record.product_name)}_${record.product_id}_${record.order_date}`}
               loading={loading}
               dataSource={data}
               pagination={{
                   pageSize: pageSize,
                   current: pageNum,
                   total: data.length,
                   onChange: (page, pageSize) => {
                       // console.log("page", page)
                       setPageNum(page)
                       setPageSize(pageSize)
                   }
               }}/>
    </div>
}
